<template>
  <div>
    <techprocesses-edit></techprocesses-edit>
  </div>
</template>

<script>
import TechprocessesEdit from "@/components/ecologist/techprocesses/techprocessesEdit";
export default {
  name: "techprocessesEditView",
  components: { TechprocessesEdit },
};
</script>

<style scoped></style>
